export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    let options = {
      rootMargin: "0px",
      threshold: 1,
    }

    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          animateProgressBar(entry.target)
        }
        else {
          if(entry.target.classList.contains('progress-bar-reverse')) {
            entry.target.style.width = "100%"
          }
          else {
            entry.target.style.width = "0";
          }
        }
      });
    };

    let observer = new IntersectionObserver(callback, options);

    const progress_bars = document.querySelectorAll('.progress-bar');

    progress_bars.forEach(function(progress_bar){
      observer.observe(progress_bar);
    });

    function animateProgressBar(progress_bar) {
      // let count = 0;
      // let limit = progress_bar.dataset.width;
      // let duration = Math.floor(3000 / limit);
      // let bar_counter = setInterval(() => {
      //   count = count + 1;
      //   progress_bar.style.width = count + "%";
      //   if(count >= limit) {
      //     clearInterval(bar_counter);
      //   }
      // }, duration);
      progress_bar.style.width = progress_bar.dataset.width + "%";
    };

    //spacing hacks
    $('h3:contains("Farmer Livelihoods")').css('margin-top', '35px');
    $('h3:contains("Responsible Marketing")').css('margin-top', '35px');
  },
};
