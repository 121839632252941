export default {
  init() {
    let options = {
      rootMargin: "0px",
      threshold: [0, 1.0],
    };
  
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
        if(entry.isIntersecting) {
          if(entry.intersectionRatio >= 0.75) {
            const count_elements = document.querySelectorAll('.count-up');
  
            countUp(entry.target);
          }
        }

        // else if(entry.intersectionRatio == 0){
        //   entry.target.textContent = "0";
        // }
      });
    };
  
    let observer = new IntersectionObserver(callback, options);

    const count_ups = document.querySelectorAll('.count-up');
    count_ups.forEach(function(count_up) {
      observer.observe(count_up);
    });
 
  },
  finalize() {

    //End Finalize Section
  },
};
