export default {
  init() {

  },
  finalize() {
    let options = {
      rootMargin: "0px",
      threshold: 0.5,
    };
  
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting && entry.target.classList.contains('count-up')) {
          countUp(entry.target);
        }

        // else {
        //   let count_elements = entry.target.querySelectorAll('.count-up');
  
        //   count_elements.forEach(function(count_element) {
        //     count_element.textContent = "0";
        //   });
        // }

        if(entry.isIntersecting && entry.target.classList.contains('observe-video-container')) {
          let videos = entry.target.querySelectorAll('.pie-animation');
          videos.forEach(function(video) {
            video.play();
          });
        }
      });
    };
  
    const observer = new IntersectionObserver(callback, options);

    const observe_sections = document.querySelectorAll('.count-up');
    observe_sections.forEach(function(observe_section) {
      observer.observe(observe_section);
    });

    const observe_videos = document.querySelector('.observe-video-container');
    observer.observe(observe_videos);
    //end init
    //End Finalize Section

    window.addEventListener('load', () => {
      //add padding to text area for picture of plastic bottle shirt man
      if(window.innerWidth < 900) {
        const shirt_man = document.querySelector('.safety-milestones .image-column');
        const featured_text = document.querySelector('.safety-milestones .featured-text');
        const shirt_man_height = shirt_man.offsetHeight + 30;
        featured_text.style.marginBottom = shirt_man_height + "px";
      }
    });
  },
};
