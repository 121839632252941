export default {
  init() {

  },
  finalize() {
    let options = {
      rootMargin: "0px",
      threshold: 0.5,
    };
  
    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
        if(entry.isIntersecting) {
          let count_elements = entry.target.querySelectorAll('.count-up');

          count_elements.forEach(function(count_element) {
            countUp(count_element);
          });
        }

        else {
          let count_elements = entry.target.querySelectorAll('.count-up');
  
          count_elements.forEach(function(count_element) {
            count_element.textContent = "0";
          });
        }
      });
    };
  
    let observer = new IntersectionObserver(callback, options);

    const observe_sections = document.querySelectorAll('.observe-for-count');
    observe_sections.forEach(function(observe_section) {
      observer.observe(observe_section);
    });
    //end init
    //End Finalize Section
  },
};
