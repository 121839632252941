export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    let options = {
      rootMargin: "0px",
      threshold: 0.5,
    };
  
    let callback = (entries, observer) => {
      entries.forEach((entry) => {

        if(entry.isIntersecting && entry.target.classList.contains('observe-video-container')) {
          let videos = entry.target.querySelectorAll('.pie-animation');
          videos.forEach(function(video) {
            video.play();
          });
        }
      });
    };
  
    const observer = new IntersectionObserver(callback, options);

    const observe_videos = document.querySelector('.observe-video-container');
    observer.observe(observe_videos);
    //end init
    //End Finalize Section
  },
};
